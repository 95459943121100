@media print
  
  .printable
    background: white
    position: absolute
    top: 0
    left:0
    width: 100%
    height: 100%
    z-index: 10000
a 
  cursor: pointer

.primary
  &.bg
    background-color: #dee6d4
    color: black

.MuiContainer-root
  padding: 0.5rem 1rem 4rem !important
.MuiDivider-root::before
  //width: 24px !important
@font-face 
  font-family: 'Empera'
  src: url('Empera-Regular.woff2') format('woff2') /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

.MuiChip-root
  &.MuiChip-clickable.colored:hover
    filter: brightness(1.2)
  &.tiny
    height: 19px !important
    border-radius: 3px !important
    padding: 0px
  .MuiChip-deleteIcon
      transition: max-width 0.25s
      max-width: 1em !important
  &.hide-remove
    .MuiChip-deleteIcon      
      max-width: 0px !important
      transition: max-width 0.25s  

h1,h2,h3,h4,h5,h6
  font-family: 'Empera', sans-serif !important
  letter-spacing: 0.08rem !important
  margin:0
  &.thin
    font-weight: 300 !important

.overlay
  pointer-events: none
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 8
  background:#ffffff00
  transition: background 0.5s
  &.shown
    pointer-events: auto
    background:#ffffff77
    transition: background 0.5s
  
.invert
  filter: invert(1)

.fade
  opacity: 0.5
.fade-more
  opacity: 0.25
  
.fade-unless-hovered
  opacity: 0.5
  transition: opacity 0.25s
  &:hover
    opacity: 1

.unlink
  color: inherit !important
  text-decoration: none !important
  cursor: default
  
// .list-header
//   &.sticky
//     position: fixed
//     z-index:1
//     transition: transform 0.25s
//     top: 0

.popup_alert
  padding-top:0
  overflow: visible !important
  // filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32))
  margin-top: 8px
  position:relative
  &.over
    margin-top:0
  &.center 
    &::before
      left: 50%
      transform: translateX(-50%)  translateY(-50%) rotate(45deg)
  &.right
    // margin-left: 21px
    &::before
      right: 0
      transform: translateY(-50%) rotate(45deg)
  &.left
    &::before
      left: 0
      transform: translateY(-50%) rotate(45deg)
  &:before
    content: ""
    display: block
    position: absolute
    top: 0      
    width: 10px
    height: 10px
    background-color: white
    transform: translateY(-50%) rotate(45deg)
    z-index: 0
  &.over:before
    top: auto
    bottom:-8px
  ul
    padding-top: 0
    padding-bottom: 0

.course-details
  .MuiAlert-root
    padding: 0 16px
    align-items: center
  .enrollment-status
    border-radius: 0  
    .MuiPaper-root
      border-radius: 0  
  
    
    

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop
  margin-bottom: 12px 

.sub-title
  font-size: 0.9rem
  color: #666666
  &.caps
      text-transform: uppercase
  &.xs
      font-size: 0.8rem
  &.xxs
      font-size: 0.75rem
    
  &.inherit
    color: inherit
  &.MuiTableCell-root
    color: #666666 !important
    
.inherit
  color: inherit !important
  font-size: inherit !important

ul.MuiList-root
  padding-top: 0
  padding-bottom: 0

#root a
  cursor: pointer
  &.inherit
    color: inherit
    text-decoration-color: inherit

.MuiCheckbox-root.tight
  padding: 4px

.eval-comments
  max-height: 300px
  position: relative
  &.has-more:not(.show-more)
    &::after
      content: ""
      display: block
      
      pointer-events: none
      position: absolute
      z-index: 1
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%)
  &.has-more
    &.show-more
      max-height: none
      &::after
        display:none
  .show-more-button
    position: absolute
    bottom: 0
    z-index: 2
    display: block
    right: 0
    left: 0
  
.MuiChip-root.boxy
  border-radius: 4px


.page-title
  color: #000000
  text-transform: none !important

  .MuiButton-endIcon
    margin-left: 0
    svg
      font-size: 32px

.loading-bg
  background: linear-gradient(180deg, #f8f8f8aa, #e0e0e0aa)
  background-color: transparent !important

.system-select
  .MuiSelect-select
    padding-top: 3px
    padding-bottom: 3px
  fieldset
    border: none

.tree
  .node-actions
    visibility: hidden
  &.editing
    .add-node, .edit-node
      visibility: hidden
  .leaf
    .MuiAccordionSummary-expandIconWrapper
        visibility: hidden
    .MuiAccordionSummary-root, &.new-node    
        &::before
          content: ""
          display: block
          position: absolute
          top: 0
          left: 0
          height: 50%
          width: 16px
          border-bottom: dotted 1px #ccc
  .MuiAccordionSummary-root
    flex-direction: row-reverse
    min-height: 0
    margin: 0
    padding: 0 0 0 8px
    &:hover
      .node-actions
        visibility: visible
    &.Mui-expanded
      .MuiAccordionSummary-expandIconWrapper
        transform: rotate(90deg)
    .expand-icon
      font-size: 0.8rem
  .MuiAccordionSummary-content
    margin-left: 8px
    min-height: 0
    margin: 2px 0 2px 4px
    
  .MuiAccordionDetails-root
    padding: 0px 0px 0px 14px
    padding: 0
    border-left: dotted 1px #ccc
    margin: 0 0 0 14px
  .node
    position: relative
    &:last-child 
      &:not(:only-child)
        &::before
          content: ""
          display: block
          position: absolute
          bottom:0
          left: 0
          width: 2px
          margin-left: -2px
          height: 50%
          background: white
  input
    padding: 2px 8px
  .add-node, .edit-node
    color: #ccc

.eval 
  .eval-error
    padding:8px
    text-align: center
    position: absolute
    left: 0
    right: 0
    top:0
    z-index: 1
    transform: translateY(-0%)
    transition: transform 0.25s 
    &.shown
      transform: translateY(-100%)
      transition: transform 0.25s 
  .section-title
    background:#f6f6f6
    padding: 8px 16px
    font-size: 1rem
    font-weight: 500
    
  .prompt
    font-weight: 500
  .radio, .checkbox
    .MuiFormControlLabel-root
      x-align-items: flex-start
    .MuiFormControlLabel-label
        padding-top: 6px
  .yes-no, .radio, .checkbox
    .MuiFormControlLabel-label
      font-size: 0.9rem
    .other-text
      flex: 0.8
      margin-top:0
      input
        padding: 4px 8px
  .invalid-answer
    .prompt
      color: #d32f2f
    .yes-no, .radio, .checkbox
      color: #d32f2f
      svg path
        color: #d32f2f

.warning-stripes
  background-image: repeating-linear-gradient(45deg,#ffeaa522 0px,#ffeaa522 25px,#fff 25px,#fff 50px)

.data-grid-row-clickable
  cursor: pointer
  &:hover
    background-color: #f6f6f6
.eval-report
  .sub-title
    font-size: 0.8rem
    font-weight: 500
    text-transform: uppercase
  .answer-indent
    margin-left: 10px
    padding-left: 20px
    padding-top: 8px
    padding-bottom: 8px
    border-left: dotted 1px #999
    position: relative
    white-space: pre-wrap
    font-weight: 600
    color: #444
    &::after
      content: ''
      position: absolute
      height: 50%
      bottom: -1px
      left: 0
      width: 12px
      border-top: dotted 1px #999
      border-width: 1px 0 0 1px
      border-left: solid 1px white
      margin-left: -1px
      
  .multi-choice
    padding-left: 28px
    padding-top: 8px
    .answer-indent
      margin-left: 10px
      
    .item-checked
      font-weight: 600
      color: #444
      align-items: flex-start
    .item-unchecked
      font-weight: normal
      color: #999
      align-items: flex-start
    .item-label
      padding-top: 2px
      padding-bottom: 8px
  
  .course-list
    .selected
      background-color: #f6f6f6

.unit-selector-filled, .user-selector-filled
  fieldset
    legend
      max-width: 100%
    
.flex-tabs
  
  .MuiAccordionSummary-root
    min-height: 0 !important
    &.Mui-expanded
      border-bottom: solid 1px #f0f0f0
      font-weight: bold
      background-color: #f6f6f6

  .MuiAccordionSummary-content
    margin-top:12px !important
    margin-bottom:12px !important


  &.tabs
    .MuiAccordion-root
      &:before
        background-color: transparent !important
      

    .MuiAccordionDetails-root
      > .MuiBox-root 
        padding: 0 !important

.MuiLinearProgress-bar
  animation-duration: 1.5s !important

.color-radios
  svg
    display: none
  .MuiRadio-root > span
    display: none
  .MuiRadio-root > input+span
    display: block
    padding: 8px
    outline-width: 1px
    outline-offset: 1px
    outline-style: none
    border-radius: 50%
  .Mui-checked > input + span
    outline-style: solid

.expando
  // position: absolute
  z-index: 10
  
  background: white
  width: 100%
  box-shadow: 0px 3px 3px 0px #44444400
  transition: box-shadow 0.25s
  overflow: hidden
  
  .expando-content
    margin-top: -100%
    transition: margin-top 0.5s
    overflow: hidden
  
  &.shown
    // position: absolute
    box-shadow: 0px 3px 3px 0px #44444499
    transition: box-shadow 0.25s
    .expando-content
      margin-top: 0%
      transition: margin-top 0.5s
  .tag-filters
    max-height: 300px
      
    
.course-dates
  &>div
    padding-top: 8px
    padding-bottom: 8px
    padding-left: 36px
    position: relative
    &:after
      content: ""
      display: block
      position: absolute
      top: 0
      left: 16px
      right: 0
      bottom: 0
      border-left: solid 1px #999
      pointer-events: none

    &::before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 16px
      width: 12px
      bottom: 50%
      border-bottom: solid 1px #999
      pointer-events: none
    &:not(:last-child)
        
    &:last-child
      margin-bottom: 0px
      &::after
        bottom: 50%

.rosters
  .MuiListItemButton-root
    overflow: hidden
  .MuiChip-root
    height: 20px !important
  .roster-count
    color: #000
    padding-left: 100%
    position: relative
    height: 1.5rem
    width: 1.5rem
    border-radius: 100px
    overflow: hidden
    background-color: #999
    >div
      color: white !important
      position: absolute
      left: 0
      top: 0
      bottom: 0
      right: 0
      display: flex
      align-items: center
      justify-content: center
      font-size: 0.8rem
      
  
    
.MuiListItemIcon-root
  min-width: 36px !important

.filter-drawer
  position: absolute
  top: 0
  right: 0
  transform: translateX(100%)
  bottom: 0px
  z-index: 2 
  background-color: white
  transition: transform 0.25s !important
  &.open
    transform: translateX(0%)
    transition: transform 0.25s !important
  
.bold
  font-weight: 600 !important

.verification-drawer
  position: absolute
  
  right: 0
  left: 0
  transform: translateY(120px)
  bottom: 0px
  z-index: 2 
  background-color: white
  transition: transform 0.25s !important
  &.open
    transform: translateX(0)
    transition: transform 0.25s !important

.pulse
  animation: pulse 2s infinite ease-in-out
  animation-play-state: running


@keyframes pulse
  0%
    opacity: 0.5
    transform: scale(1)
  50%
    opacity: 1
    transform: scale(1.25)
  100%
    opacity: 0.5
    transform: scale(1)

.drop-target
  background: red