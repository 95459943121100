

html {
  background: linear-gradient(180deg, #fff, #e0e0e0);
  background-attachment: fixed;
}

.full-height {
  min-height: 100%;
}

.success-bg {
  background-color: rgb(243, 244, 239);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}

.card-header {
  padding: 8px;
  font-weight: bold;
  color: white;
}

.section-header {
  
  padding-bottom: 8px;
  
  font-weight: bold;
}

.likert-table thead th {
  line-height: 1.25;
  color: #606060;
}
.likert-table tbody tr:nth-child(odd) td {
  background-color: #f6f6f6;
}

.likert-table tbody tr:nth-child(even) td {
  background-color: white;
}

.likert-table tbody tr.invalid-answer .MuiTableCell-body
{
  color: red;
}

.likert-table tbody tr.invalid-answer .MuiTableCell-body svg path
{
  color: red;
}

.likert-table tbody tr.invalid-answer:nth-child(odd) td {
  background-color: #ffe6e6;
}

.likert-table tbody tr.invalid-answer:nth-child(even) td {
  background-color: #fff6f6;
}

.yes-no.invalid-answer 
{
  color: red;
}

.yes-no.invalid-answer svg path
{
  color: red;
}

